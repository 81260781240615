<template>
    <div>
        <b-card>
            <h2
                class="text-blue font-weight-bold mt-1"
                v-html="$t('currency')"
            ></h2>
        </b-card>
        <b-card class="row-height">
            <b-row cols="12">
                <b-col class="mb-2 col-md-12">
                    <b-button @click="addCurrencyModal = true" variant="outline-primary">{{ $t('add_new_currency') }}
                    </b-button>
                </b-col>
                <b-table
                    :fields="fields"
                    :items="currencies"
                    class="position-relative"
                    hover
                    responsive
                    striped
                >
                </b-table>
            </b-row>
        </b-card>

        <b-modal
            hide-header-close
            no-close-on-backdrop
            scrollable
            size="md"
            style="padding-top: 10vh;"
            v-model="addCurrencyModal"
            @ok="createCurrency()"
        >
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
                <b-form-group
                    :label="$t('name') + ':'"
                    id="currencyName"
                    label-for="currency_name"
                >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('name')"
                        rules="required"
                    >
                        <b-input-group>
                            <b-form-input
                                class="form-control"
                                id="currency_name"
                                v-model="currencyData.name"
                            >
                            </b-form-input>
                        </b-input-group>
                        <span class="text-danger"></span>
                    </validation-provider>
                </b-form-group>

                <b-form-group
                    :label="$t('code') + ':'"
                    id="currencyCode"
                    label-for="currency_code"
                >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('code')"
                        rules="required"
                    >
                        <b-input-group>
                            <b-form-input
                                class="form-control"
                                id="currency_code"
                                v-model="currencyData.code"
                            >
                            </b-form-input>
                        </b-input-group>
                        <span class="text-danger"></span>
                    </validation-provider>
                </b-form-group>

                <b-form-group
                    :label="$t('sign') + ':'"
                    id="currencySign"
                    label-for="currency_sign"
                >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('sign')"
                        rules="required"
                    >
                        <b-input-group>
                            <b-form-input
                                class="form-control"
                                id="currency_sign"
                                v-model="currencyData.sign"
                            >
                            </b-form-input>
                        </b-input-group>
                        <span class="text-danger"></span>
                    </validation-provider>

                </b-form-group>
            </ValidationObserver>

        </b-modal>

    </div>
</template>

<script>
  import { extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import {ValidationObserver, ValidationProvider} from "vee-validate";

  extend('required', required);

  import {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BLink,
    BRow
  } from "bootstrap-vue";

  export default {

    components: {
      BRow, BCol, BLink, BFormGroup, BFormInput, BFormCheckbox, BCardText,
      BCardTitle, BForm, BButton, BInputGroup, BFormSelect, ValidationProvider, ValidationObserver
    },

    data() {
      return {
        currencyData: {
          name: null,
          code: null,
          sign: null
        },
        addCurrencyModal: null,
        currencies: null,
        fields: [
          {
            key: "id",
            label: "id"
          },
          {
            key: "name",
            label: "name"
          },
          {
            key: "code",
            label: "code"
          },
          {
            key: "sign",
            label: "Sign"
          },
          // {
          //   key: "actions",
          //   label: "actions"
          // }
        ]
      }
    },

    created() {
      this.getCurrencies();
    },

    methods: {
      getCurrencies() {
        this.$http.post("/currency").then((response) => {
          if (response.data.status == 200) {
            this.currencies = response.data.value;
          }
        });
      },

      createCurrency() {
        this.$http.post("/currency/store", this.currencyData).then((response) => {
          if (response.data.status == 200) {
            this.currencyData.name = null;
            this.currencyData.code = null;
            this.currencyData.sign = null;
            this.getCurrencies();
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>